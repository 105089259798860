import React, { useEffect, useState } from 'react';

function HomePage() {
  const [dogImage, setDogImage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const breeds = [
      "shiba", "akita", "husky", "samoyed", "malamute", 
      "cardigan/corgi", "pitbull", "chihuahua", "terrier/yorkshire",
      "retriever/golden", "pomeranian", "dachshund", "pug", 
      "germanshepherd", "rottweiler"
    ];

    async function fetchDogImage() {
      try {
        const randomBreed = breeds[Math.floor(Math.random() * breeds.length)];
        const response = await fetch(`https://dog.ceo/api/breed/${randomBreed}/images/random`);
        const data = await response.json();
        if (isMounted && data.status === "success") {
          console.log("Image fetched successfully: ", data.message);
          setDogImage(data.message);
          setTimeout(() => {
            if (isMounted) {
              setLoading(false);
            }
          }, 1000); // Ensure at least 1 second passes before setting loading to false
        } else {
          console.error("Error fetching image data: ", data);
        }
      } catch (error) {
        console.error("Error occurred while fetching the dog image: ", error);
      }
    }

    fetchDogImage();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div style={{ textAlign: 'center', padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFE9D5', minHeight: '100vh', position: 'fixed', width: '100%', height: '100%', overflow: 'hidden', boxSizing: 'border-box' }}>
      {/* Top-left logo */}
      <img
        src="/logo.png"
        alt="Logo"
        onClick={() => window.location.reload()}
        style={{
          position: 'absolute',
          top: '20px',
          left: '20px',
          cursor: 'pointer',
          width: '50px',
          height: '50px',
        }}
      />
      
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '30px', marginTop: '50px' }}>Hello World, this is a dog.</h1>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', height: '100%', marginTop: '20px' }}>
          <img src="/loading.gif" alt="Loading..." style={{ width: '80vw', height: 'auto', maxWidth: '400px', maxHeight: '300px', animation: 'spin 1s linear infinite' }} />
        </div>
      ) : (
        dogImage && (
          <img
            src={dogImage}
            alt="A random dog"
            loading="lazy"
            style={{ maxWidth: '100%', height: 'auto', maxHeight: '66vh', margin: '0 auto' }}
          />
        )
      )}
      <div style={{ position: 'fixed', bottom: '0px', width: '100%', backgroundColor: '#FFE9D5', textAlign: 'center', padding: '15px 0' }}>
        <div style={{ fontSize: '1.3rem', color: '#808080', marginBottom: '10px' }}>CA: to be determined</div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginBottom: '10px' }}>
          <a href="https://t.me/ethdog_erc" target="_blank" rel="noopener noreferrer">
            <img src="/tglogo.png" alt="Telegram" style={{ width: '30px', height: '30px' }} />
          </a>
          <a href="https://x.com/ethdog_erc" target="_blank" rel="noopener noreferrer">
            <img src="/xlogo.png" alt="X" style={{ width: '30px', height: '30px' }} />
          </a>
          <a href="https://www.dextools.io/app/en/token/pepe" target="_blank" rel="noopener noreferrer">
            <img src="/dexlogo.png" alt="DexTools" style={{ width: '30px', height: '30px' }} />
          </a>
        </div>
        <div style={{ fontSize: '1rem', color: '#808080' }}>$ETHDOG @ All rights reserved</div>
      </div>
    </div>
  );
}

export default HomePage;
